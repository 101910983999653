import { Layout, Menu, Row, Col, Button, Tooltip, Select } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { useState, useEffect, createContext, useContext } from "react";
import styles from "./auth.module.scss";
import {
  adminMenuLinks,
  superAdminMenu,
  SettingsMenu,
  companyAdminLinks,
  siteAdminLinks,
  MenuLink,
} from "./rolesMenu/adminMenu";
import { useRouter } from "next/router";
import Image from "next/image";
import logoImg from "../../../../public/icons/ShiftSwap_Logo_RGB.png";
import sympolLogo from "../../../../public/icons/ShiftSwap_Symbol.png";
import { useAuth } from "../../../context/authContext";
import { workerMenuLinks } from "./rolesMenu/workerMenu";
// import Breadcrumbs from "nextjs-antd-breadcrumbs";
import { getTitle } from "./routesToTitles";
import useSwitchTo from "../../../../hooks/useSwitchTo";
import { useTranslation } from "next-i18next";
import { LanguageSwitcher } from "./languageSwitcher";

const { Header, Sider, Content } = Layout;
type props = {
  action?: any;
  children?: any;
};

//TODO Fix props type
const AuthView: React.FC<props> = (props: any) => {
  const { t } = useTranslation("layout");

  const router = useRouter();
  const { pathname, asPath } = router;
  const [collapsed, setCollapsed] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const { state: user, dispatch } = useAuth();
  const { backAdmin } = useSwitchTo();

  const [title, setTitle] = useState<string | null | undefined>();

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const pushTo = (href: string) => {
    router.push(href);
  };
  const TitleContext = createContext("");

  const isFeatureAllowed = (menuLinks: MenuLink[]) => {
    if (user.campusAllowed) {
      return menuLinks;
    }
    return menuLinks.filter((link) => link.href !== "/campuses");
  };
  const checkUserLinks = () => {
    const { userData } = user;
    if (userData?.role === "ADMIN" || userData?.role === "EMPLOYER") {
      if (userData?.employer?.title === "COMPANY_ADMIN") {
        // console.log("company_admin", userData?.employer.title);
        return isFeatureAllowed(companyAdminLinks(t));
      } else if (userData?.employer?.title === "SITE_ADMIN") {
        // console.log("super_admin", userData?.employer.title);
        return isFeatureAllowed(siteAdminLinks(t));
      } else {
        return isFeatureAllowed(adminMenuLinks(t));
      }
    } else if (userData?.role === "WORKER") {
      return workerMenuLinks(t);
    } else if (userData?.role === "SUPER_ADMIN") {
      return superAdminMenu(t);
    } else return [];
  };

  useEffect(() => {
    const translatedTitle = getTitle(asPath);
    if (!translatedTitle) {
      setTitle(null); // allow empty titles instead of having hanging titles
      return;
    }
    translatedTitle != translatedTitle.toUpperCase() &&
      setTitle(translatedTitle);
  }, [getTitle(asPath)]);

  useEffect(() => {
    if (user.title) {
      setTitle(user.title);
    }
  }, [user.title]);

  const signOut = () => {
    router.push("/signin").then(() => {
      localStorage.clear();
      dispatch({ type: "LOGOUT" });
      window.location.href = "/";
    });
  };

  return (
    <Layout style={{ backgroundColor: "#fff", height: "100vh" }}>
      <Sider
        trigger={null}
        collapsible
        breakpoint="sm"
        onBreakpoint={(broken) => {
          setIsMobileScreen(broken);
          setCollapsed(broken);
        }}
        collapsed={collapsed}
        width={255}
        className={styles.sider}
        collapsedWidth={isMobileScreen ? 0 : 80}
      >
        <div className={styles.logo_container}>
          <Image
            src={collapsed ? sympolLogo : logoImg}
            height={collapsed ? 40 : 100}
            width={collapsed ? 60 : 150}
            alt="logo"
          />
        </div>
        {localStorage.getItem("adminToken") && (
          <Row justify="center" onClick={backAdmin}>
            {collapsed ? (
              <Tooltip title="search">
                <Button type="primary" shape="circle" icon={<SwapOutlined />} />
              </Tooltip>
            ) : (
              <Button type="primary">Back Admin</Button>
            )}
          </Row>
        )}
        <Menu
          theme="dark"
          mode="inline"
          className={styles.menu_style}
          selectedKeys={[pathname]}
        >
          {checkUserLinks().map((link) => {
            if (!link.children) {
              return (
                <Menu.Item
                  key={link.href}
                  icon={link.icon}
                  onClick={() => {
                    pushTo(link.href);
                    if (isMobileScreen) setCollapsed(true);
                  }}
                >
                  <span className={styles.menu_label}>{link.label}</span>
                </Menu.Item>
              );
            } else {
              return (
                <Menu.SubMenu
                  key={link.href}
                  title={link.label}
                  icon={link.icon}
                >
                  {link.children.map((link) => {
                    return (
                      <Menu.Item
                        key={link.href}
                        icon={link.icon}
                        onClick={() => {
                          pushTo(link.href);
                          if (isMobileScreen) setCollapsed(true);
                        }}
                      >
                        <span className={styles.menu_label}>{link.label}</span>
                      </Menu.Item>
                    );
                  })}
                </Menu.SubMenu>
              );
            }
          })}

          <Menu.Item
            key="logout"
            icon={<LogoutOutlined style={{ fontSize: 24 }} />}
            onClick={() => signOut()}
          >
            <span className={styles.menu_label}>{t("LOGOUT")}</span>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-header" style={{ padding: 0 }}>
          <Row align="top" justify="start">
            <Col span={8}>
              <span
                className={`trigger ${styles.collapse_btn}`}
                onClick={toggle}
              >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                {/*  <Breadcrumbs
                  style={{ marginLeft: 10, display: "inline", color: "white" }}
                  rootLabel="Home"
                  omitRootLabel={true}
                /> */}
              </span>
            </Col>
            <Col span={8}>
              <h2
                style={{
                  textTransform: "capitalize",
                  color: "white",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {title}
              </h2>
            </Col>
            <Col style={{ textAlign: "end" }} span={8}>
              <span className="dark" style={{ paddingRight: "10px" }}>
                <LanguageSwitcher />
              </span>
            </Col>
          </Row>
        </Header>

        <Content
          className="site-layout-background"
          style={{
            margin: "12px 8px",
            padding: isMobileScreen ? 30 : 12,
            minHeight: 280,
            position: "relative",
            overflow: "auto",
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AuthView;
