import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { notification } from "antd";
import fetch from "isomorphic-unfetch";
import * as Sentry from "@sentry/nextjs";

const httpLink = createHttpLink({
  credentials: "include",
  fetch,
  uri: process.env.NEXT_PUBLIC_API_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");

  // get the translation header
  const localeCookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith("NEXT_LOCALE="))
    ?.split("=")[1];
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      locale: localeCookie,
    },
  };
});

const initSentry = (operation: any) => {
  if (operation?.getContext()?.headers) {
    let transactionId = operation?.getContext()?.headers["x-request-id"];
    if (transactionId) {
      Sentry.configureScope((scope) => {
        scope.setTag("transaction_id", transactionId);
      });
    }
    let currentUserEmail = localStorage.getItem("email");
    if (currentUserEmail) {
      Sentry.setUser({ email: currentUserEmail });
    }
  }
  if (operation.operationName) {
    Sentry.configureScope((scope) => {
      scope.setTag("operationName", operation.operationName);
    });
  }
  if (operation.variables) {
    let variables = { ...operation.variables };
    if (variables.password) {
      delete variables.password;
    }
    Sentry.configureScope((scope) => {
      scope.setExtra("variables", variables);
    });
  }
  if (operation.query.loc.source.body) {
    Sentry.configureScope((scope) => {
      scope.setExtra("query", operation.query.loc.source.body);
    });
  }
};

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  initSentry(operation);
  const appVersion = operation
    .getContext()
    ?.response?.headers?.get("x-backend-git-commit-sha")
    ?.substring(0, 9);
  const clientAppVersion =
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA?.substring(0, 9);

  if (appVersion && clientAppVersion && appVersion !== clientAppVersion) {
    notification["error"]({
      message: "Version Error",
      description: `A new update found! Please refresh your browser`,
      placement: "bottomRight",
    });
  }
  if (graphQLErrors) {
    graphQLErrors.map(({ message, path }) => {
      if (`${path}` != "UpdateWorkersbyExeclSheet")
        notification["error"]({
          message: `${path}`,
          description: `${message} ${path}`,
          placement: "bottomRight",
        });
    });
    //console.log(graphQLErrors);
  }

  if (networkError)
    notification["error"]({
      message: `NetworkError`,
      description: `${networkError}`,
      placement: "bottomRight",
    });
});

const client = new ApolloClient({
  credentials: "include",
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    // mutate: { errorPolicy: "all" },
    query: { fetchPolicy: "network-only" },
    watchQuery: { fetchPolicy: "network-only" },
  },
});

export default client;
