import { i18n } from "next-i18next";
import { SHIFT_TABS_INFO } from "../../../../../constants";

export const getTitle = (route?: string) => {
  if (!route) return null;
  const baseRouteTitles: Record<string, string | undefined> = {
    "/shifts": i18n?.t("layout:SHIFTS"),
    [`/shifts?active=${SHIFT_TABS_INFO.TABLE.query}`]: i18n?.t(
      "layout:SHIFTS_SUMMARY"
    ),
    [`/shifts?active=${SHIFT_TABS_INFO.CALENDAR.query}`]: i18n?.t(
      "layout:SHIFTS_CALENDAR"
    ),
    [`/shifts?active=${SHIFT_TABS_INFO.CALENDAR_SUMMARY.query}`]: i18n?.t(
      "layout:SHIFTS_CALENDAR_SUMMARY"
    ),
    "/admins": i18n?.t("layout:EMPLOYERS"),
    "/workers": i18n?.t("layout:WORKERS"),
    "/myprofile": i18n?.t("layout:MY_PROFILE"),
    "/companies": i18n?.t("layout:COMPANIES"),
    "/sites": i18n?.t("layout:SITES"),
    "/skills": i18n?.t("layout:SKILLS"),
    "/kpis": i18n?.t("layout:KPIS"),
    "/logout": i18n?.t("layout:LOGOUT"),
    "/my-shifts": i18n?.t("layout:MY_SHIFTS"),
    [`/my-shifts?active=${SHIFT_TABS_INFO.TABLE.query}`]:
      i18n?.t("layout:MY_SHIFTS"),
    [`/my-shifts?active=${SHIFT_TABS_INFO.CALENDAR.query}`]: i18n?.t(
      "layout:MY_SHIFTS_CALENDAR"
    ),
    [`/my-shifts?active=${SHIFT_TABS_INFO.CALENDAR_SUMMARY.query}`]: i18n?.t(
      "layout:MY_SHIFTS_CALENDAR_SUMMARY"
    ),
    [`/grab-shift?active=${SHIFT_TABS_INFO.TABLE.query}`]: i18n?.t(
      "layout:GRAB_A_SHIFT"
    ),
    "/grab-shift": i18n?.t("layout:GRAB_A_SHIFT"),
    [`/grab-shift?active=${SHIFT_TABS_INFO.CALENDAR.query}`]: i18n?.t(
      "layout:GRAB_A_SHIFT_CALENDAR"
    ),
    "/post-shift": i18n?.t("layout:POST_A_SHIFT"),
    "/mysites": i18n?.t("layout:MY_SITES"),
    "/mycompany": i18n?.t("layout:MY_COMPANY"),
    "/users": i18n?.t("layout:USERS"),
    "/invoicing-kpis": i18n?.t("layout:INVIOISING_KPIS"),
    "/request": i18n?.t("layout:REQUESTS"),
    "/activity-logs": i18n?.t("layout:ACTIVITY_LOGS"),
    "/usage-reports": i18n?.t("layout:USAGE_REPORTS"),
  };
  let bestMatch = "";
  let bestMatchTitle: string | undefined;

  for (const [pattern, title] of Object.entries(baseRouteTitles)) {
    if (route.includes(pattern) && pattern.length > bestMatch.length) {
      bestMatch = pattern;
      bestMatchTitle = title;
    }
  }
  return bestMatchTitle;
};
