import { gql, useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import React from "react";
import { useAuth } from "../components/context/authContext";
import { beforeSwitching } from "../utils/utils";

export const SWITCH_TO = gql`
  mutation SWITCH_TO($userId: Int!) {
    toBeUser(id: $userId) {
      token
      user {
        id
        email
        role
        first_name
        last_name
        status
        worker {
          id
          canDo
        }
        employer {
          title
        }
      }
    }
  }
`;

export const useSwitchTo = () => {
  const { state, dispatch } = useAuth();
  const router = useRouter();

  const [switchTo] = useMutation(SWITCH_TO, {});

  const switchToUserByUserId = (userId: number) => {
    localStorage.setItem("cameFrom", router.asPath);
    switchTo({
      variables: {
        userId: userId,
      },
    }).then(({ data }: any) => {
      router.push("/").then(() => {
        beforeSwitching();
        dispatch({ type: "LOGIN", payload: data.toBeUser.token });
        dispatch({ type: "USER_DATA", payload: data.toBeUser.user });
        router.reload();
      });
    });
  };

  const backAdmin = () => {
    const adminToken = localStorage.getItem("adminToken");
    const cameFrom = localStorage.getItem("cameFrom");

    dispatch({ type: "LOGIN", payload: adminToken });
    localStorage.removeItem("adminToken");
    localStorage.removeItem("cameFrom");
    window.location.href = `${cameFrom ?? "/"}`;
  };

  return { switchToUserByUserId, backAdmin };
};

export default useSwitchTo;
