export const STATUS_MAP: { [key: string]: string } = {
  FREE: "Free",
  APPROVED: "Approved",
  CANCELED: "Canceled",
  REJECTED: "Denied",
  SELECTED: "Pending Approval",
  POSTED: "Posted",
};

export const SHIFT_TYPES_MAP: { [key: string]: string } = {
  OVERTIME: "OT",
  VTO: "VTO",
  ASSIGNMENT: "LT",
  WORKER: "WS",
  CAMPUS: "CMPS",
};
export const TIME_ZONES = [
  {
    value: "America/New_York",
    label: "Eastern Time",
  },
  {
    value: "America/Chicago",
    label: "Central Time",
  },
  {
    value: "America/Denver",
    label: "Mountain Time",
  },
  {
    value: "America/Los_Angeles",
    label: "Pacific Time",
  },
];
export const SHIFT_TABS_INFO = {
  TABLE: {
    query: "table",
    key: "1",
  },
  CALENDAR: {
    query: "calendar",
    key: "2",
  },
  CALENDAR_SUMMARY: {
    query: "calendarSummary",
    key: "3",
  },
  UNRELEASED_SHIFTS: {
    query: "UnreleasedShifts",
    key: "4",
  },
};
